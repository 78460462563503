<template>
	<div class="page">
		<router-view />
	</div>
</template>

<script>

	export default {
		
		data() {
			return {
				
			}
		},
		mounted() {
			
			
		},
		methods: {
			
		}
	}
</script>