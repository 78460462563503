<template>
	<div>
		<div class="header">
			<div class="menubar">
				
				<img v-if="$store.state.config.leftlogo" :src="$store.state.config.leftlogo" alt="">
			</div>
			<div class="headright">
				<div class="moudelname">教师请假</div>
				<div style=" float: right; width: 230px;">
					<div class="nickname  flexCenter">
						<span style="cursor: pointer; border: 0; outline: none; color: #888;">
							欢迎您:{{ userName }} <!-- <i class="el-icon-arrow-down"></i> -->
						</span>
					</div>
					<div class="headimg">


						<img :src="avatar" alt="">
					</div>
				</div>

				<div style="margin-right:300px ; margin-left: 150px; text-align: center; min-width: 300px; overflow: hidden;">
					<div class="bigmenu">
						<div :class="[
								  'bmitem bgBlue flexCenter',
								  nowbMenu == item.id ? 'bmckd' : '',
								]" v-for="(item, idx) in Menus" :key="idx" @click="goBMenu(item)">
							<i :class="item.icon"></i> {{ item.title }}
						</div>
					</div>
				</div>
			</div>

		</div>
		<div class="menuline"></div>
		
		

		<div :class="['leftmenu',$store.state.leftmenu?'':'hidemenu'] ">
			<div style="width: 160px; margin: 0 auto; height: 100%;">
				<div style="height: 70px;"></div>

				<div class="smenubox" style=" border-radius: 6px;">
					<div :class="['menuitem',nowsMenu==item.id?'menuckd':'']" v-for="(item,idx) in Smenus" :key="idx"
						@click="goPage(item)">
						<i :class="item.icon"></i> {{item.title}}
						<div class="ckdtail">

						</div>
					</div>
				</div>
			</div>
		</div>

		


		<el-dialog title="修改密码" :visible.sync="isShowModal" :close-on-click-modal="false" :destroy-on-close="true"
			width="500px">
			<el-form :model="pwdForm">
				<el-form-item>
					<el-input placeholder="请输入原密码" v-model="pwdForm.oldPwd" auto-complete="off">
						<template slot="prepend"><span style="display: inline-block; width: 60px">原密码</span></template>
					</el-input>
				</el-form-item>
				<el-form-item>
					<el-input type="password" placeholder="请输入新密码" v-model="pwdForm.newPwd1" auto-complete="off">
						<template slot="prepend"><span style="display: inline-block; width: 60px">新密码</span></template>
					</el-input>
				</el-form-item>

				<el-form-item>
					<el-input type="password" placeholder="请再次输入新密码" v-model="pwdForm.newPwd2" auto-complete="off">
						<template slot="prepend"><span style="display: inline-block; width: 60px">确认密码</span></template>
					</el-input>
				</el-form-item>

				<div style="height: 20px;text-align: center;padding-top: 30px;padding-bottom: 20px;">
					<el-button type="primary" style="width: 100%" @click="savePwd">更改密码</el-button>
				</div>
			</el-form>
		</el-dialog>


	</div>
</template>

<script>
	import aes from "../../utils/aes.js";

	export default {
		data() {
			return {
				
				avatar: "",
				Menus: [],
				Smenus: [],
				nowbMenu: 0,
				nowsMenu: "",
				userName: "",
				isShowModal: false,
				isShowYearModal: false,
				ltmenuHide: true,
				pwdForm: {
					oldPwd: "",
					newPwd1: "",
					newPwd2: "",
				},
			};
		},
		mounted() {

      let arr = ['/daInquiry_h5','/daInquiry','/daInquiry_h5_bdz','/daInquiry_bdz']
      if(arr.indexOf(this.$route.path)>-1){
      }else {
        this.getRoleMenu();
      }

		},
		watch: {
			Smenus: function(n, o) {
				this.$parent.leftMenus = n;
			},
		},
		methods: {
			
			getRoleMenu() {

				this.$http.post("/api/leave_teacher_menu").then(res => {
					this.Menus = res.data
					//this.getConfig();
					this.getTeacherInfo();
					for (let item of this.Menus) {
						if (item.path && this.$route.path.indexOf(item.path) >= 0) {
							this.nowsMenu = 0
							this.nowbMenu = item.id
							this.$store.dispatch("showSmenu", 0)
						} else {
							for (let sitem of item.children) {
								if (sitem.path && this.$route.path.indexOf(sitem.path) >= 0) {
									this.nowsMenu = sitem.id
									this.nowbMenu = item.id
									this.Smenus = item.children;
									this.$store.dispatch("showSmenu", 1)
									break
								}
							}
						}
					}
				})
			},
			goBMenu(item) {
				this.Smenus = item.children;
				this.nowbMenu = item.id;
				if (this.Smenus && this.Smenus.length > 0) {
					this.goPage(this.Smenus[0]);
					this.nowsMenu = this.Smenus[0].id;
					this.$store.dispatch("showSmenu", 1)
				} else if (item.path) {
					if (this.$route.path != item.path) {
						this.$router.push(item.path);
					}
					this.$store.dispatch("showSmenu", 0)
				}
			},
			goPage(item) {
				if (this.$route.path != item.path) {
					this.$router.push(item.path)
					this.nowsMenu = item.id
				}
			},
			setYear() {
				localStorage.setItem("year", this.year);
				window.location.reload();
			},
			handleCommand(e) {
				console.log(e);
				if (e == "updPwd") {
					this.isShowModal = true;
				} else if (e == "logout") {
					localStorage.clear();
					sessionStorage.clear();
					this.$router.push("/login");
				} else if (e == "changeYear") {
					this.isShowYearModal = true;
				}
			},
			savePwd() {
				if (!this.pwdForm.oldPwd) {
					this.$message.error("请输入原密码");
					return;
				}

				if (!this.pwdForm.newPwd1) {
					this.$message.error("请输入新密码");
					return;
				}
				if (!this.pwdForm.newPwd2) {
					this.$message.error("请再次输入新密码");
					return;
				}
				if (this.pwdForm.newPwd1 != this.pwdForm.newPwd2) {
					this.$message.error("两次密码不一致");
					return;
				}
				this.$http.post("/api/reset_myself_pwd", this.pwdForm).then((ok) => {
					if (ok.data.code) {
						if (ok.data.code == 500) {
							this.$message.error(ok.data.msg);
						} else {
							this.$message.error("保存失败");
						}
					} else {
						this.$message.success("修改成功");

						this.isShowModal = false;
						this.$router.push("/login");
					}
				});
			},
			getConfig() {
				this.$http.post("/api/sch_configs").then(res => {
					for (let k of res.data) {

						if (k.ktype == 'logo2') {
							//this.leftLogo = k.kvalue
						}
					}


				})
			},
			getTeacherInfo() {

				this.$http.post("/api/get_teacher_info").then(res => {
					this.avatar = res.data.avatar
					this.userName = res.data.username
					this.$store.dispatch("setTeacher", res.data)
				})
			},

		},
	};
</script>
<style scoped lang="less" type="text/less">
	.moudelname{float: left; margin-top: 20px; margin-left: 20px;}
	
	
.leftmenu {
  width: 200px;
  height: 100%;
  position: absolute;
  
  transition: all .3s;
  transform-origin: left;
 

  .menuitem {
    padding: 10px;
    text-align: left;

    cursor: pointer;
    margin: 0 auto;

	font-size: 14px;
  }

  // .menuitem:first-child {
  //   border-top-left-radius: 4px;
  //   border-top-right-radius: 4px;
  // }

  // .menuitem:last-child {
  //   border-bottom: 1px solid #0794b3;
  //   border-bottom-left-radius: 4px;
  //   border-bottom-right-radius: 4px;
  //   border-radius: 4px;
  // }

  .menuckd {
    // background-color: #ffcc11 !important;
  }
}
.menuline{ height: 2px; border: 2px solid #b9cbdf; position: absolute; top: 50px; width: 100%; background-color: #e1e9f9;}
.hidemenu {
  transform: scale(0, 1);
}

.smenubox {
		width: 160px;
		padding-left: 20px;
		padding-top: 30px;
		margin: 0 auto;
		background-color: #fff;
		height: 86%;
		box-shadow: 2px 2px 6px 2px rgba(24, 144, 255, 0.1);
	}

	.hidemenu {
		transform: scale(0, 1);
	}

	.menuitem {
		padding: 10px;
		text-align: left;
		cursor: pointer;
		margin: 0 auto;

		color: #333333;

	}

	.menuckd {

		color: #1890ff;
		box-shadow: 0 0 6px 2px rgba(24, 144, 255, 0.2) inset;
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
		transform: translateX(10px);
		position: relative;
		background-color: #fafbfc;
	}

	.menuckd .ckdtail {
		width: 40px;
		height: 80px;
		position: absolute;
		top: -20px;
		right: -5px;
		background-image: url(//aiwx.org.cn/static/images/tail.png);
		background-size: auto 100%;

		background-repeat: no-repeat;
		background-color: #fafbfc;



	}

.header {
  height: 50px;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
	background-color: rgba(247,248,250,0.9);

  .menubar {
    width: 200px;
    height: 100%;
    float: left;
    color: #fff;
    text-align: center;
    line-height: 50px;

    img {
      width: 100%;
      margin-top: 0px;
    }
  }

  .headright {
    height: 100%;
    margin-left: 200px;
	
    position: relative;
	text-align: center;

    .bigmenu {
     
      height: 40px;
	  padding-top: 12px;
      color: #626874;
      .bmitem {
        height: 100%;
        padding: 5px 40px 0px 40px;
        border-radius: 4px;
        cursor: pointer;
		display: inline-block;
		vertical-align: top;
		
		margin-right: 10px;
	
      }

      .bmckd {
        
		background-image: url(//aiwx.org.cn/static/images/menubg.png);
		background-size: 100% 100%;
		line-height: 50px;
		padding-top: 0;
		color: #409efe;
		font-weight: bolder;
      }
    }

    .nickname {
      position: absolute;
      right: 60px;
      //top: 10px;
      height: 100%;
      line-height: 100%;
      color: #fff;
      padding: 0 20px;
      border-radius: 4px;
    }

    .headimg {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 10px;
      right: 20px;
      border: 1px solid  #0794b3;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
}
</style>
