import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import indexframe from '../views/index_frame.vue'
Vue.use(VueRouter)

const routes = [{
	path: '/wxqc',
	name: 'wxqc',
	component: resolve => require(['../views/h5/wxqc'], resolve),
}, {
	path: '/ivst',
	name: 'ivst',
	component: resolve => require(['../views/h5/ivst'], resolve),
}, {
	path: '/p/:id',
	name: 'ivstplan',
	component: resolve => require(['../views/h5/ivstplan'], resolve),
}, {
	path: '/index',
	name: 'indexframe',
	component: indexframe,
	children: [{
		path: '/list', name: '',
		component: resolve => require(['../views/pages/list'], resolve)
	},
	{
		path: '/suggestion', name: '校友留言',
		component: resolve => require(['../views/pages/message/alumniSuggestion'], resolve)
	},
	{
		path: '/alumniInfo', name: '校友信息',
		component: resolve => require(['../views/pages/alumniInfo/alumniInfo'], resolve)
	},
	{
		path: '/event', name: '优秀毕业生',
		component: resolve => require(['../views/pages/alumniInfo/event'], resolve)
	},
	{
		path: '/activity', name: '校友活动',
		component: resolve => require(['../views/pages/alumniInfo/activity'], resolve)
	},
	{
		path: '/forum', name: '校友问答',
		component: resolve => require(['../views/pages/alumniInfo/forum'], resolve)
	},
	{
		path: '/news1', name: '校内新闻',
		component: resolve => require(['../views/pages/news/news1'], resolve)
	},
	{
		path: '/news2', name: '通知公告',
		component: resolve => require(['../views/pages/news/news2'], resolve)
	}, {
		path: '/ivstlist', name: '问卷模板',
		component: resolve => require(['../views/pages/ivst/list'], resolve)
	}, {
		path: '/ivstLog', name: '发送记录',
		component: resolve => require(['../views/pages/ivst/ivst_send_log'], resolve)
	},
	{
		path: '/edit/:id', name: '',
		component: resolve => require(['../views/pages/ivst/edit'], resolve)
	},
	{
		path: '/campus', name: '校园风景',
		component: resolve => require(['../views/pages/photoAlbum/campus'], resolve)
	},
	{
		path: '/classPhoto', name: '班级相册',
		component: resolve => require(['../views/pages/photoAlbum/classPhoto'], resolve)

	},
	{
		path: '/seting', name: '',
		component: resolve => require(['../views/pages/seting/ngz_seting'], resolve)

	},
	{
		path: '/plan', name: '问卷计划',
		component: resolve => require(['../views/pages/plan/plan'], resolve)

	}, {
		path: '/report', name: '问卷报告',
		component: resolve => require(['../views/pages/plan/report'], resolve)

	},
	{
		path: '/suverySum', name: '开发中',
		component: resolve => require(['../views/pages/plan/suverySum'], resolve)

	},
	{
		path: '/fillLog', name: '填写记录',
		component: resolve => require(['../views/pages/plan/plan_fill_log'], resolve)
	},
	{
		path: '/homePage', name: '',
		component: resolve => require(['../views/home/home'], resolve)

	},
	{
		path: '/major', name: '专业组织架构',
		component: resolve => require(['../views/pages/seting/major'], resolve)

	},
	{
		path: '/teachers', name: '教师信息',
		component: resolve => require(['../views/pages/teacher/teachers'], resolve)

	},
	{
		path: '/jtseting', name: '',
		component: resolve => require(['../views/pages/seting/jt_seting'], resolve)

	},
	{
		path: '/setting', name: '同步数据（超管专用）',
		component: resolve => require(['../views/pages/seting/setting'], resolve)

	},
	]
},
{
	path: '/daInquiry', name: '档案查询',
	component: resolve => require(['../views/pages/GraduateFileInquiry/pc_index.vue'], resolve)
},
{
	path: '/daInquiry_h5', name: '',
	component: resolve => require(['../views/pages/GraduateFileInquiry/h5.vue'], resolve)
},
{
	path: '/daInquiry_bdz', name: '报到证查询',
	component: resolve => require(['../views/pages/GraduateFileInquiry/pc_index_bdz.vue'], resolve)
},
{
	path: '/daInquiry_h5_bdz', name: '',
	component: resolve => require(['../views/pages/GraduateFileInquiry/h5_bdz.vue'], resolve)
},
]



const router = new VueRouter({
	// mode: 'history',
	base: process.env.BASE_URL,
	routes
})


//获取url参数的方法，支持vue路由各种参数写法
const $request = function (name) {
	var params = [];
	var value = null;
	if (window.location.href.indexOf(name + "=") >= 0) {
		let q = window.location.href.split("?")[1];
		if (q.indexOf("#") > 0) {
			q = q.split("#")[0];
		}
		params = q.split("&");
		for (let p of params) {
			if (p.indexOf(name + "=") >= 0) {
				value = p.replace(name + "=", "").replace(/\//g, "")
				break;
			}
		}
	}
	if (value && value != undefined && value != "undefined") {
		return value
	} else {
		return "";
	}
}


//路由进入之前检查是否登录
router.beforeEach((to, from, next) => {
	console.log(178178, to, from, next)
	let auth = $request("auth"); //检查url是否带有登录字符串
	let pmid = $request("pmid")
	console.log(180180, auth, pmid)

	if (pmid) { //检查本地是否登录
		window.sessionStorage.setItem("pmid", pmid); //将pmid字符串保存到缓存
	}
	if (auth) { //检查本地是否登录
		window.sessionStorage.setItem("auth", auth); //将登录字符串保存到缓存
		next();
	} else if (window.sessionStorage.getItem("auth")) {
		next();
	} else {
		next();
	}


})



export default router
